<template>
  <div class="hold-transition login-page bg-login view-user-forgot-password">
    <div id="app" class="login-box">
      <div class="card">
        <div class="card-body login-card-body">
          <template v-if="forgotPasswordStatus == false">
            <div class="row">
              <div class="col-12 text-center mb-3">
                <img
                  class="logo-login-img"
                  src="@/assets/img/dentcloud-logo-darkblue.png"
                />
                <h3 class="logo-caption mt-2">
                  <span class="">D</span>ent<span class="">C</span>loud
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h3>ลืมรหัสผ่าน?</h3>
                <p>
                  ใส่ที่อยู่อีเมลที่ลงทะเบียนไว้
                  แล้วเราจะส่งอีเมลเพื่อรีเซ็ตรหัสผ่านให้
                </p>
              </div>
            </div>
            <!-- <b-form submit.prevent="sendForgotPassword"> -->
            <b-form>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="email">อีเมล</label>
                    <input
                      type="email"
                      class="form-control form-control-sm required"
                      id="email"
                      v-model="forgotPasswordForm.email"
                      required
                      lazy
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    @click="sendForgotPassword"
                    type="button"
                    class="btn btn-block btn-primary btn-sm"
                  >
                    ส่งอีเมลเพื่อรีเซ็ตรหัสผ่าน
                  </button>
                </div>
              </div>
            </b-form>
          </template>
          <template v-if="forgotPasswordStatus == true">
            <div class="row">
              <div class="col-12">
                <h2 class="text-warning text-center">
                  <i class="fas fa-exclamation-circle"></i>
                  ระบบได้ส่งอีเมลเพื่อรีเซ็ตรหัสผ่านไปแล้ว
                </h2>
                <p class="text-center">
                  กรุณาตรวจสอบอีเมล เพื่อทำการรีเซ็ตรหัสผ่าน
                </p>
                <p class="text-center">หากไม่พบ กรุณาเช็ค junkmail</p>
                <a href="/login" class="btn btn-block btn-primary"
                  >ลงชื่อเข้าใข้</a
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue";

export default {
  name: "ForgotPasswordViews",
  components: { Loading },
  data() {
    return {
      forgotPasswordForm: {
        email: "",
      },
      forgotPasswordStatus: false,
      isLoading: false,
    };
  },
  created() {},
  methods: {
    ...mapActions({
      forgotPassword: "moduleUser/forgotPassword",
    }),
    sendForgotPassword() {
      this.isLoading = true;

      this.forgotPassword(this.forgotPasswordForm)
        .then((res) => {
          if (res.status == 200) {
            this.forgotPasswordStatus = true;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>